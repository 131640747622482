$md1: 460px; // Phones
$md2: 800px; // Tabs
$md3: 1280px; // Desktop
$md4: 9999px; // Wide desktop

// $header-height: 5rem;
// $menu-height: 2.5rem;
// $title-height: 3rem;

$small-radius: 0.5rem;
$medium-radius: 1rem;
$large-radius: 3rem;

$popup_width: 85vw;
$popup_height: 60vh;



:root {
  --title-color: light-dark(#3a3a3a, #fff);
  --text-color: #2a2a2a;

//    --topbar-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.2);
  --popup-gradient: linear-gradient(#84d881, 5%, #dcfbdb);
  --card-gradient: linear-gradient(to bottom, rgb(25, 80, 57), 30%, #e6eee5);
  --card-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
  --button-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  // --video-shadow: 1rem 0 10px 1px rgba(0, 0, 0, 0.3), -1rem 0 10px 1px rgba(0, 0, 0, 0.3), 0 1rem 10px 1px rgba(0, 0, 0, 0.3), 0 -1rem 10px 1px rgba(0, 0, 0, 0.3);
  // --video-shadow: 1rem 0 10px 1px rgba(0, 0, 0, 0.3), -1rem 0 10px 1px rgba(0, 0, 0, 0.3)/*, 0 1rem 10px 1px rgba(0, 0, 0, 0.3), 0 -1rem 10px 1px rgba(0, 0, 0, 0.3)*/;
  --video-shadow: 0px 5px 25px rgb(30 31 33 / 12%);
  // --image-shadow: 1rem 0 10px 1px rgba(0, 0, 0, 0.3), -1rem 0 10px 1px rgba(0, 0, 0, 0.3), 0 1rem 10px 1px rgba(0, 0, 0, 0.3), 0 -1rem 10px 1px rgba(0, 0, 0, 0.3);
  --image-shadow: 0px 5px 25px rgb(30 31 33 / 12%);
  --button-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.9);

  --shadow-1: 0px 0px 10px 5px rgba(60, 60, 60, 0.2);

  --test-shadow: 0px 0px 10px 5px rgba(60, 60, 60, 0.2);

}
