$headline-contacts-font-size: 0.8rem;
$headline-contacts-font-weight: $font-weight-bold;

@at-root :root {
  --topbar-headline-contacts-icon-color: #2a2a2a;
  --topbar-headline-contacts-item-color: #505050;
  --topbar-headline-contacts-item-hover-color: #101010;
}

.contacts {
  grid-area: contacts;
  display: flex;
  align-items: center;

  &__list {
    display: block;
    list-style: inside none none;
  }

  &__item {
    display: inline-flex;
    font-size: $headline-contacts-font-size + 0.25rem;
    color: var(--topbar-headline-contacts-icon-color);

    a {
      margin: 0 25px 0 10px;
      text-decoration: none;
      border-bottom-width: 1px;
      border-bottom-style: dotted;
      border-bottom-color: var(--topbar-headline-contacts-item-color);
      @include font($headline-contacts-font-size, $headline-contacts-font-weight, 1);
      color: var(--topbar-headline-contacts-item-color);
    }

    a:hover {
      color: var(--topbar-headline-contacts-item-hover-color);
    }
  }
}