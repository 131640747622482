$headline-height: 5rem;
$menu-height: 3rem;

@at-root :root {
  --topbar-bottom-line: 1px solid #84d881;
  --topbar-shadow: 0 5px 2px 2px rgba(0,0,0, 0.1);
  --topbar-dropdown-gradient: linear-gradient(to left, white, 50%, #dcfbdb);
}

.topbar {
  display: block;
  position: sticky;
  top: -($headline-height + 1rem);
  background: transparent;
  border-bottom: var(--topbar-bottom-line);
  box-shadow: var(--topbar-shadow);
  z-index: 100000;

  &__headline {
    height: $headline-height;
  }

  @import 'headline/main';

  &__menu {
    height: $menu-height;
  }

  @import 'nav/main';

  &__dropdown {
    display: block;
    // height: auto;
  }

  @import 'dropdown/main';
}