@at-root :root {
  --dropdown-background: linear-gradient(to bottom, #dcfbdb, 95%, white);
  //--dropdown-background:  #dcfbdb;
}

@keyframes drop-down {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }    
}

@keyframes pull-in {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }    
}

.dropdown {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  // animation-name: dropdown;
  // animation-duration: 0.5s;
//  position: relative;
  z-index: -10;
  background: var(--dropdown-background);
  border-bottom: var(--topbar-bottom-line);
  box-shadow: var(--topbar-shadow);

  &__container {
    padding: 1rem 0;
  }

  // &_product {
  //   display: block;
  // }

  @import 'production';
  @import 'search';
}