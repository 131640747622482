$menu-navbar-height: 3rem;

$menu-item-hoverpad-size-h: 0.5rem;
$menu-item-hoverpad-size-v: 0.5rem;
$menu-item-hoverpad-radius: 1rem;
$menu-item-font-size: 1.1rem;
$menu-item-font-weight: $font-weight-bold;
$menu-item-left-gap: $menu-item-hoverpad-size-h;
$menu-item-right-gap: 1rem;

$menu-chevron-size: 1.5rem;
$menu-chevron-font-size: 1.2rem;
$menu-chevron-left-gap: $menu-item-hoverpad-size-h;
$menu-chevron-right-gap: 1rem;

$menu-close-size: 1.5rem;
$menu-close-font-size: 1.5rem;

@at-root :root {
//  --topbar-menu-gradient: linear-gradient(to left, #dcfbdb, 10%, #cdf7cd);
  --topbar-menu-gradient: linear-gradient(to right, #54d854, 0.1%, #dcfbdb);
  --topbar-menu-item-color: #2f2f2f;
  --menu-item-background: rgba(255, 255, 255, 0.3);
  --menu-item-shadow: 0 0 10px rgba(0,0,0,0.5);
  --menu-item-button-background: transparent;
  --menu-item-button-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
//  --menu-chevron-color: #e9303088;
  --menu-chevron-color: #00000088;
  --menu-chevron-background: transparent;
  --menu-close-color: #f00;
  --menu-close-background: transparent;
}

@mixin menu-button($font-size, $color) {
  margin-left: $menu-chevron-left-gap;
  margin-right: $menu-chevron-right-gap;
  font-size: $font-size;
  color: $color;
  position: relative;
  z-index: 2;
}

@mixin menu-button-after($size, $background) {
  display: block;
  width: $size;
  height: $size;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: var(--menu-item-button-shadow);
  background: $background;
  content: '';
}

.menu {
  display: flex;
  height: $menu-navbar-height;
  align-items: center;
  background: var(--topbar-menu-gradient);

  &__container {
    display: block;
  }

  &__list {
    display: flex;
    margin-left: -$menu-item-hoverpad-size-h;
//    justify-content: flex-end;
    align-items: center;
  }

  &__item {
    display: inline-flex;
    margin-left: $menu-item-left-gap;
    margin-right: $menu-item-right-gap;
    height: $menu-item-font-size;
    align-items: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__item_active {
    a {
      color: gray;
    }
  }
  
  &__item::after {
    display: block;
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    padding: $menu-item-hoverpad-size-v $menu-item-hoverpad-size-h;
    margin-top: -$menu-item-hoverpad-size-v;
    margin-left: -$menu-item-hoverpad-size-h;
    border-radius: $menu-item-hoverpad-radius;
    background: var(--menu-item-background);
    box-shadow: var(--menu-item-shadow);
    content: '';
    visibility: hidden;
    z-index: -2;
  }

  &__item:hover::after {
    visibility: visible;
  }
  
  &__icon {
    width: $menu-item-font-size;
    height: $menu-item-font-size;
    margin-right: $menu-chevron-right-gap;
  }  

  &__anchor {
    @include font($menu-item-font-size, $menu-item-font-weight);
    color: var(--menu-color);
    text-decoration: none;
  }
  
  &__chevron {
    @include menu-button($menu-chevron-font-size, var(--menu-chevron-color));
  }

  &__chevron::after {
    @include menu-button-after($menu-chevron-size, var(--menu-chevron-background));
  }

  &__close {
    @include menu-button($menu-close-font-size, var(--menu-close-color));
    margin-left: 0;
    // position: relative;
    // left: -3px;
    // top: 0;
  }

  &__close::after {
    @include menu-button-after($menu-close-size, var(--menu-close-background));
    transform: translate(-48%, -49%) !important;
  }

}
