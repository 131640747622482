img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;

}
























._ibg {
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}

body.ie {
    ._ibg {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    ._ibg img {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
}

