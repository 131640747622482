$production-count-per-line: 4;
$production-title-font-size: 1rem;
$production-title-font-weight: $font-weight-bold;
$production-icon-size: 6rem;
$production-icon-anchor-gap: 1.5rem;
$production-gap-h: 2.5rem;
$production-gap-v: 0.5rem;

@at-root :root {
  --production-item-background: #c5f1c5;
  --production-hoverpad-background: #dcfbdb;
  // --production-item-background: #dcfbdbff;
  // --production-hoverpad-background: #f0f8f0ff;
  --production-title-color: #202020;
  --production-icon-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.production {
  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat($production-count-per-line, 1fr);
    column-gap: $production-gap-h;
    row-gap: $production-gap-v;
    list-style: none inside none;
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: $small-radius;
    // cursor: pointer;
    overflow: hidden;
    background: var(--production-item-background);

    a {
      cursor: default;
    }

    // &:hover {
    //   background: var(--production-hoverpad-background);
    //   box-shadow: var(--production-icon-shadow);
    // }
  }

  &__icon {
    width: $production-icon-size;
    border-radius: 0;
    margin-right: $production-icon-anchor-gap;
  }

  &__anchor {
    @include font($production-title-font-size, $production-title-font-weight, 1.3);
    color: var(--production-title-color);
    text-decoration: none;
  }
}