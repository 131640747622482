:root {
    // --basement-color: #84d881;
    --basement-color: #daf8da;
    --basement-text-color: rgb(83, 74, 74);

}

.basement {
    flex: 0 0 auto;
    padding-top: 20px;
    padding-bottom: 40px;
    background: var(--basement-color);
    color: var(--basement-text-color);
    a { 
        color: var(--basement-text-color);
        text-decoration: none;
    }
    h5 {
        margin: 15px 0;
        font-weight: 900;
    }    
    &__button {
        display: block;

        margin: 0 auto;
        padding: 10px;

        border: 1px solid var(--basement-text-color);
        background: var(--basement-color);
        color: var(--basement-text-color);
    }

    &__column-list {
        display: flex;
        width: 100%;
        margin-top: 20px;
    }

    &__column-item {
        width: 25%;
        li {
            margin: 5px 0;
        }
        li.large-font {
            font-size: 2rem;
            font-weight: lighter;
            padding-bottom: 10px;
        }

    }

    &__refs {
        padding-left: 50%;
    }

    &__logo {
        width: 6rem; 
        height: 6rem; 
        border-radius: 50%; 
        padding-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        display: none;
    }

    &__cwww {
        padding-top: 10px;
        font-size: 0.75rem;
        color: var(--basement-text-color);
    }
}