$section-padding-top: 2rem;
$section-padding-bottom: 3rem;

.content {
  display: block;
  position: sticky;
  top: -6rem;
  left: 0;
  z-index: 100;

  & > section {
    padding-top: $section-padding-top;
    padding-bottom: $section-padding-bottom;
    min-height: 100vh;
  }
  
  // & > section:last-child {
  //   padding-bottom: 0;
  // }
  
  &_section:nth-child(even) {
    background-color: #f6f6f6;
  }
}