$about-padding-top: 0rem;
$about-container-padding-left: 2rem;
$about-container-padding-right: 3rem;
$about-container-padding-top: 2rem;
$about-container-padding-bottom: 5rem;
$about-visuals-gap: 1rem;

@at-root :root {
  --article-shadow: 0 0 2rem .25rem rgba(0,0,0,0.2);
  --article-background: light-dark(#f6f6f6, #0f0f0f);
  --visual-shadow: 0px 0px 10px 5px rgba(60, 60, 60, 0.2);
}

.about {
  position: relative;
  
  &__grid {
    display: flex;
    justify-content: space-between;
    padding-top: $about-padding-top;
    // grid-template-columns: repeat(2, 1fr);
    // column-gap: 1.5rem;
    //position: relative;
    //z-index: 10;
  }

  &__title {
    @include gray-pad-title(1.8rem);
  }

  &__article {
    display: block;
    width: calc(50% - 1rem);
    height: max-content;
  }

  &__container {
    padding-left: $about-container-padding-left;
    padding-right: $about-container-padding-right;
    padding-top: $about-container-padding-top;
    padding-bottom: $about-container-padding-bottom;
    background: var(--article-background);
    box-shadow: var(--article-shadow);
  }

  &__visual {
    // display: block;
    width: calc(50% - 1rem);
    position: sticky;
    top: 5rem; left: 0;
    height: fit-content;
    z-index: 10000;

    &-list {
      list-style: inside none none;
    }
    
    &-item {
      display: block;
      margin: $about-visuals-gap 0;
    }
  
    &-item:first-child {
      margin-top: 0;
    }
  
    &-item:last-child {
      margin-bottom: 0;
    }
  }

  &__video {
    width: 100% !important;
    border-radius: $small-radius;
    box-shadow: var(--visual-shadow);
  }
}



// .about {
//   &__container {
//     display: grid;
//     padding-top: 1.3rem;
//     grid-template-columns: repeat(2, 1fr);
//     column-gap: 1.5rem;
//     position: relative;
//   }

//   &__content {
//     display: block;
//   }

//   &__title {
//     @include default-title();
//   }

//   &__article {
//     display: block;
//     background-color: #f6f6f6;
//     // border-radius: 0.5rem;
//     box-shadow: 0 0 2rem .25rem rgba(0,0,0,0.2);
//   }

//   &__aside {
//     position: sticky;
//     top: 10rem;
//     left: 0;
//   // height: min-content;

//     .aside {
//       &__list {
//       list-style: inside none none;
//     }

//     &__item {
//       display: flex;
//       height: fit-content;
//       margin-bottom: 15px;

//       &:last-child {
//           margin-bottom: 0;
//       }

//       video {
//           width: 100% !important;
//           height: auto !important;
//           border-radius: .5rem;
//           box-shadow: var(--shadow-1);
//       }
//     }
//     }
//   }
// }