.carousel {

	// margin: 20px auto;
	// border: 1px dotted gray;
	/* box-sizing: content-box; */
	position: relative;

	&__container {
		display: block;
		margin: 0 -10px;
		// margin: 10px 10px 30px 10px;
	}

	&__title {
		font: 1.5rem bold serif;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	&__content {
		position: relative;
		// margin: 0 -10px;
	}

	&__slider {
		position: relative;
		overflow: hidden;
	}

	&__items {
		display: grid;
//		margin: 10px;
		grid-auto-flow: column;
		// grid-auto-columns: 100%;
		grid-column-end: 1;
		position: relative;
		top: 0;
		left: 0;
		cursor: move;
		// border: 1px solid black;
	}

	&__item {
		display: flex;
		margin: 10px;
		box-sizing: content-box;
		border-radius: 10px;
		box-shadow: var(--test-shadow);
		background: #eeeeee;
		// border: 1px solid black;

		@import 'custom.scss';
	}

	&__controls {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		appearance: none;
		background: transparent;
		border: 0;
		padding: 0;
		margin: 0;
		width: 20%;
		height: 100%;
		cursor: pointer;
		position: absolute;
		top: 0;
		z-index: 1;
		color: black;
	}

	&__button-circle {
		display: flex;
		justify-content: center;
		align-content: center;
		width: 50px;
		height: 50px;
		background: white;
		border: 2px solid transparent;
		border-radius: 50%;
		box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
	}

	&__button_left {
		left: -50px;
	}

	&__button_right {
		right: -50px;
	}

	&__bullets {
		display: flex;
		width: 10%;
		margin: 0 auto;
//		margin-bottom: 5px;
		justify-content: center;
		position: absolute;
		z-index: 1;
		left: 0;
		bottom: 10px;
//		background: #fff;
//		opacity: .5;
	}

	&__bullet {
		margin: 0 4px;
		color: black;
		font-size: 1rem;
		border-radius: .45rem;
		background: rgba(0, 0, 0, 0.2); 
		box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
		cursor: pointer;
	}

	&__bullet_active {
		cursor: default;
	}
}