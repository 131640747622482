$search-form-elems-gap: 0.5rem;

@at-root :root {
  --search-input-border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  --search-input-box-shadow: light-dark(0 0 10px rgba(0,0,0,0.5), 0 0 10px rgba(0,0,0,0.5));
}

.search {
  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: 10rem 1fr 5rem 5rem;
    column-gap: $search-form-elems-gap;
  }

  &__label {
    display: flex;
    align-items: center;
    @include default-paragraph();
  }

  &__input {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
//    transition: var(--search-input-border-color) .15s ease-in-out, box-shadow .15s ease-in-out;
    font-family: $font-family-default;
  }
}