$default-title-font: 'Roboto';
$default-text-font: 'Roboto Slab';
$default-ul-margin-top: -0.5rem;
$default-list-item-margin-top: -0.5rem;
$default-title-font-size: 1.75rem;
$default-font-size: 1rem;
$default-text-indent: 2rem;
$default-line-height: 1.8;

@at-root :root {
  --title-color-gray-pad: light-dark(#000, #fff);
  --title-background-gray-pad: light-dark(#dedede, #000);
}

@mixin font-family($font-family: $font-family-default) {
  font-family: $font-family;
}

@mixin font($size, $weight: $font-weight-default, $line-height: $default-line-height) {
  // @include font-family($font-family-default);
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
}

@mixin title($font-size) {
  display: flex;
  @include font($font-size, $font-weight-bold);
}

@mixin paragraph($font-size, $text-indent: 0, $line-height: $default-line-height) {
  // @include font-family($default-text-font);
  @include font($font-size, $font-weight-normal, $line-height);
  color: var(--default-text-color);
  text-indent: $text-indent;
  text-align: left;
}

@mixin unsorted-list($font-size, $text-indent: 0, $list-style: inside disc none, $margin-top: 0) {
  // @include font-family($default-text-font);
  @include font($font-size, $font-weight-normal);
  margin-top: $margin-top;
  text-indent: $text-indent;
  color: var(--default-text-color);
  list-style: $list-style;
}

@mixin unsorted-list-item($line-height: $default-line-height, $margin-top: 0) {
  margin-top: $margin-top;
  line-height: $line-height;
}

@mixin anchor($font-size: $default-font-size, $font-color: var(--default-text-color), $pseudo-selectors-font-color: var(--default-text-color)) {
  // @include font-family($default-text-font);
  @include font($font-size, $font-weight-normal);
  padding-bottom: calc(0.125 * $font-size);
  text-decoration: underline;
  // text-decoration: none;
  // border-bottom-width: 1px;
  // border-bottom-style: dotted;
  // border-bottom-color: $font-color;
  color: $font-color;

  :hover, :visited, :active {
    border-bottom-color: $pseudo-selectors-font-color;
    color: $pseudo-selectors-font-color;
  }
}

@mixin default-title() {
  @include font-family($default-title-font);
  @include title($default-title-font-size);
}

@mixin gray-pad-title($font-size, $padding-left: 0, $margin-bottom: 1.5rem) {
  padding-left: $padding-left;
  margin-bottom: $margin-bottom;
  margin-left: -2rem;
  padding: 0.5rem 0;
  @include font-family('Roboto');
  @include title($font-size);
  text-indent: 2rem;
  text-transform: uppercase;
  color: var(--title-color-gray-pad);
  background-color: var(--title-background-gray-pad);
}

@mixin default-paragraph() {
  @include font-family();
  @include paragraph($default-font-size);
}