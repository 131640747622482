$headline-logo-size: 5rem;
$headline-title-font-size: 3rem;
$headline-title-font-weight: 900;
$headline-top-padding: 0.5rem;
$headline-bottom-padding: 0.5rem;


@at-root :root {
  --topbar-headline-gradient: linear-gradient(to top, #dcfbdb, 95%, white);
  --topbar-headline-title-color: #0f0f0f;
}

.headline {
  background: var(--topbar-headline-gradient);
  padding-top: $headline-top-padding;
  padding-bottom: $headline-bottom-padding;
  @include font-family('Roboto');

  &__container {
    display: grid;
    grid-template-columns: $headline-logo-size 1rem 1fr;
    grid-template-rows: 1.5rem 3.5rem;
    grid-template-areas:
      'logo gap contacts'
      'logo gap title'
    ;
  }

  &__logo {
    grid-area: logo;
    width: $headline-logo-size;
    height: $headline-logo-size;
    border-radius: 50%;
    background: url('/@/img/logo.jpg') no-repeat center / contain white;
    background-size: calc($headline-logo-size - 15px);
  }

  &__title {
    grid-area: title;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    @include font($headline-title-font-size, $headline-title-font-weight, 1);
    color: var(--topbar-headline-title-color);
  }
  
  &__contacts {
    grid-area: contacts;
    display: flex;
    align-items: flex-end;

    @import 'contacts';
  }
}

