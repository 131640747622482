$contacts-padding-top: 3rem;
$contacts-padding-bottom: 3rem;

$contacts-container-padding-left: 2rem;
$contacts-container-padding-right: 3rem;
$contacts-container-padding-top: 2rem;
$contacts-container-padding-bottom: 5rem;

@at-root :root {
  --contacts-shadow: 0 0 2rem .25rem rgba(0,0,0,0.2);
  --contacts-background: light-dark(#f6f6f6, #0f0f0f);
}

.contacts {
  padding-top: $contacts-padding-top;
  padding-bottom: $contacts-padding-bottom;
 
  &__title {
    @include gray-pad-title(1.8rem);
  }

  &__container {
    box-sizing: border-box;
    padding-top: $contacts-padding-top;
    padding-left: $contacts-container-padding-left;
    padding-right: $contacts-container-padding-right;
    padding-top: $contacts-container-padding-top;
    padding-bottom: $contacts-container-padding-bottom;
    background: var(--contacts-background);
    box-shadow: var(--contacts-shadow);
  }

  &__grid {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 22rem);
    grid-template-areas:
      'address map'
      'road office'
    ;
    column-gap: 2rem;
    row-gap: 2rem;
  }

  &__map {
    grid-area: map;
    box-shadow: var(--contacts-shadow);
    border-radius: $medium-radius;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: $medium-radius;
    }
  }

  &__address {
    grid-area: address;
    display: flex;
    align-items: center;
    box-shadow: var(--contacts-shadow);

    &-list {
      width: 100%;
      display: block;
      padding: 1.5rem 3rem;
      box-sizing: border-box;
      flex-direction: column;
      list-style: none inside none;
    }
  
    &-item {
      @include font-family('Roboto');
      @include font(1rem, 400);
      margin: 0.5rem 0;
    }

    .button {
      width: 100%;
      padding: 0.75rem 0;
      margin-top: 4em;
      display: block;
      text-decoration: none;
      text-align: center;
      border: 1px solid #aaa;
      background: #ccc;
      color: black;
      border-radius: $small-radius;
      box-shadow: var(--contacts-shadow);
      box-sizing: border-box;
      i {
        margin-left: 0.5rem;
      }
    }

    .fa-copy {
      cursor: pointer;
    }
  }

  &__road {
    border-radius: $small-radius;
    background: url('/@/img/roadmap.jpg')  no-repeat center / contain transparent;
    box-shadow: var(--contacts-shadow);
  }

  &__office {
    border-radius: $small-radius;
    background: url('/@/img/office.jpg')  no-repeat center / contain transparent;
    box-shadow: var(--contacts-shadow);
  }

}