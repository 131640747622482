@charset 'utf-8';
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto Slab:wght@100;400;900&display=swap');

$font-family-default: 'Roboto';
$font-size-default: 16px;
$font-size: 1rem; // basefont 1rem=16px
$font-weight-thin: 100;
$font-weight-normal: 400;
$font-weight-bold: 900;
$font-weight-default: $font-weight-normal;
$font: '$font-size-default/$font-weight-default $font-family-default';


@import 'carousel';

@import 'scss/vars';
@import 'scss/mixins';

html {
  font-family: $font-family-default;
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 1024px;
  width: auto;
  height: 100%;
//  overflow-x: hidden;
  position: relative;
}

.wrapper {
  flex: 1 0 auto;
}

.layout_fluid {
  width: 100%;
}

.layout_container {  
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

:root {
  color-scheme: light;
}

@import 'header/main';
@import 'content/main';
@import 'pages/main';
@import 'footer/main';