$referencelist-container-padding-left: 2rem;
$referencelist-container-padding-right: 2rem;
$referencelist-container-padding-top: 2rem;
$referencelist-container-padding-bottom: 5rem;

@at-root :root {
  --referencelist-container-shadow: 0 0 2rem .25rem rgba(0, 0, 0, 0.2);
  --referencelist-container-background: light-dark(#f6f6f6, #0f0f0f);
  --referencelist-item-background: light-dark(white, black);
  --referencelist-item-shadow: 0px 0px 10px 5px rgba(60, 60, 60, 0.2);
}

.referencelist {
  &__container {
    padding-left: $referencelist-container-padding-left;
    padding-right: $referencelist-container-padding-right;
    padding-top: $referencelist-container-padding-top;
    padding-bottom: $referencelist-container-padding-bottom;
    background: var(--referencelist-container-background);
    box-shadow: var(--referencelist-container-shadow);
    height: calc(100vh - 20%);
  }

  &__title {
    @include gray-pad-title(1.8rem);
  }

  &__list {
    width: 100%;
    list-style: none inside none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 20px;
  }

  &__item {
    // display: grid;
    // grid-template-columns: 2rem 1fr;
    // grid-template-rows: 1fr 2rem;
    // grid-template-areas:
    //     'note note'
    //     'icon options'
    // ;
    padding: 1rem 2rem;
    border-radius: $medium-radius;
    background-color: var(--referencelist-item-background);
    box-shadow: var(--referencelist-item-shadow);

    // &-note {
    //   grid-area: note;
    //   display: flex;
    //   flex-direction: column;
    // }

    &-title {
      font-size: 1.1rem;
      font-weight: 900;
      line-height: 1.5;
      text-decoration: none;
      cursor: pointer;
      color: black;
    }

    &-changed {
      font-weight: 400;
      font-size: .8rem;
      font-style: oblique;
      margin-bottom: 0.25rem;
      cursor: help;
      color: black;
      display: none;
    }

    // &-icon {
    //   grid-area: icon;
    //   display: block;
    //   width: 1.8rem;
    //   height: 1.8rem;
    //   background: url('/@/icon/ms-word.svg') no-repeat center / contain transparent;
    // }
  
    // &-action-list {
    //   grid-area: options;
    //   list-style: square inside none;
    //   display: flex;
    //   align-items: center;
    // }
  
    // &-action-item {
    //   display: inline-block;
    //   color: #3e3e3e;
    //   font-weight: 600;
    //   text-decoration: none;
    //   padding-bottom: 3px;
    //   border-bottom: 1px dotted gray;
    //   margin-right: 1rem;
    //   cursor: pointer;
    // }

    &:last-child() { 
      margin-right: 0;
    }
  
    &:nth-child(odd) { 
      background-color: #f0f0f0;
    }

    &_selected {
      background: #dcfbdb !important;
    }
  }

  &__table-tab-list {
    margin-top: 2rem;
  }

  &__table-tab-item {
    display: none;

    &_selected {
      display: block;
    }
  }

  &__table {
    width: 100%;
//    height: min-content;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 0.8rem;

    thead tr, tbody tr {
      // margin: 1px;
      padding: 3px 1px;
      border-radius: 0.5rem;
      box-sizing: border-box;
    }

    thead {
      tr {
        padding-bottom: 0.3rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        background: #c5f1c5;

        td, th {
          box-sizing: border-box;
          margin: 2px;
          padding: 0.5rem;
          border-radius: 0.5rem;
          font-weight: 900;
          background: #dcfbdb;
        }
      }
    }

    tbody {
      display: block;
      height: calc(100vh - 20rem);
      overflow-y: visible;
      overflow-x: hidden;
      scrollbar-width: none;
      background: #dcfbdb;

      tr {
        td, th {
          margin: 2px;
          padding: 0.5rem;
          border-radius: 0.5rem;
          background: #fafffa;
        }
      }

      tr:nth-child(even) {
        background: #dcfbdb;
      }

      tr:nth-child(odd) {
        background: #c5f1c5;
      }

      tr:last-child {
        border: 0px;
      }
    }

//     tfoot {
//       tr {
//         height: 2rem;
//         padding: 1px;
//         padding-top: 0.5rem;
//         background: #c5f1c5;
//         font-weight: 900;
//         border-bottom-left-radius: 0.5rem;
//         border-bottom-right-radius: 0.5rem;
//       }

//       td {
//         //width: 100%;
//         margin: 1px;
//         padding: 0.5rem;
//         display: block;
//         box-sizing: border-box;
//         border-radius: 0.5rem;
// //        background: #c5f1c5;
//         background: #dcfbdb;
//       }
//     }

    a {
      text-decoration: none;
      border-bottom: 1px solid black;
      color: black;
    }

    tbody td:nth-child(1) {
      font-weight: 900;
      background: #84d881;
    }
  }

  &__table::after {
    display: block;
    width: 100%;
    height: 2rem;
    background: #c5f1c5;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    content: '';
  }

  .referencelist-1 tr {
    width: 100%;
    display: grid;
    grid-template-columns: 2rem 5rem 5rem 6rem 15rem 10rem 1fr 1fr 1fr;
    grid-auto-flow: column;
    box-sizing: content-box;
  }

  .referencelist-2 tr {
    width: 100%;
    display: grid;
    grid-template-columns: 2rem 5rem 5rem 6rem 15rem 10rem 1fr;
    grid-auto-flow: column;
    box-sizing: content-box;
  }

  .referencelist-3 tr {
    width: 100%;
    display: grid;
    grid-template-columns: 2rem 5rem 5rem 12rem 25rem 1fr 1fr;
    grid-auto-flow: column;
    box-sizing: content-box;
  }
}