.popup {
    position: fixed;
    display: block;
    visibility: hidden;
    padding: 20px;
    width: $popup_width;
    height: $popup_height;
    top: calc((100vh - $popup_height) / 2);
    left: calc((100vw - $popup_width) / 2);
    z-index: 100000;
    border-radius: var(--medium-radius);
    background: var(--popup-gradient);
    box-shadow: var(--drawer-shadow);

    &__button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--big-radius);
        height: var(--big-radius);
        border-radius: 50%;
        background-color: black;
        color: white;
        font-size: calc(var(--large-radius) - .75rem);
        box-shadow: var(--button-shadow);
        cursor: pointer;
    }

    &__button_close {
        top: 5px;
        right: 5px;
    }

    &__button_resize {
        top: 5px;
        right: calc(var(--large-radius) + 10px);
    }

    &__container {
        width: 100%;
        height: 100%;
        background-color: white;
    }
}
