$common-text-font-size: 1rem;
$common-text-title-font-size: 1.75rem;

$common-text-paragraph-vertical-gap: 1rem;

$common-text-line-height: 1.6;
$common-text-indent: 2rem;
$common-text-list-style: inside disc none;
$commont-text-list-margin-top: -0.65rem;
$commont-text-list-item-margin-top: -0.5rem;

@at-root :root {
  --about-text-color: light-dark(#000, #fff);
  --about-visuals-shadow: 0px 0px 10px 5px rgba(60, 60, 60, 0.2);
}

.text_common {
  @include font-family('Roboto Slab');
  color: var(--common-text-color);

  h1, h2, h3, h4, h5, h6 {
//    @include font-family('Roboto');
    @include gray-pad-title($common-text-title-font-size);
  }

  // Paragraph <p>
  p {
    margin-top: $common-text-paragraph-vertical-gap;
    margin-bottom: $common-text-paragraph-vertical-gap;
    @include paragraph($common-text-font-size, $common-text-indent, $common-text-line-height);
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }

  // Unsorted list <ul>
  ul, ol {
    @include unsorted-list($common-text-font-size, $common-text-indent, $common-text-list-style, $commont-text-list-margin-top);
 
    li {
      @include unsorted-list-item($common-text-line-height, $commont-text-list-item-margin-top);
      margin-bottom: calc($common-text-font-size / $common-text-line-height);
    }  
  }

  // Anchor <a>
  a {
    @include anchor($common-text-font-size, var(--common-text-color), var(--common-text-color));
  }
}

.text_contact {
  @include font-family('Roboto');
  color: var(--common-text-color);

  i {
    margin-right: 1rem;
  }

  span {
    color: #333;
  }

  a {
    text-decoration: none;
    border-bottom: 1px dotted #333;
    padding-bottom: 0.125rem;
    color: #333;
    margin-right: 1rem;
    :hover, :active, :visited {
      color: #333;
    }
  }

}